  
.contact-page {
    margin: 10px;
  }

  .contact-page h2 {
    color: var(--color-primary);
    margin-bottom: 10px;
  }
  
  .contact-page p {
    color: var(--color-text);
    margin-bottom: 20px;
  }
  
  .contact-card {
    padding: 0;
  }
  .contact-card div {
    display: flex;
    align-items: start;
    flex-direction: column;
    margin: 0 10px;
  }
  .contact-card div h3 {
    margin: 0px;
  }
  
  .contact-card {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .contact-card a {
    color: var(--color-primary);
    text-decoration: none;
    margin-left: 10px;
  }
  
  .contact-card a:hover {
    text-decoration: underline;
  }
  
  .contact-icon {
    color: var(--color-secondary);
    margin-right: 10px;
  }

  .contact-image {
    width: 100px;
    border-radius: 50%;
  }

  .contact-cards {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (min-width: 600px) {
    .contact-cards {
      flex-direction: row;
      justify-content: space-between;
    }
    .contact-cards > .contact-card-container:nth-child(2) .contact-card{
      margin-right: 10px;
      flex-direction: row-reverse;
    }
    .contact-cards > .contact-card-container:nth-child(2) .contact-card > div {
      align-items: end;
    }
  }