h3 {
  text-align: center;
}
p {
  padding-left: 10px;
  padding-right: 10px;
}

.checklist li {
  list-style-type: '□';
  padding-inline-start: 1ch;  

}