@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda');

body {
  display: flex;
  font-family: 'Bodoni Moda';
  margin: 0 auto;
  --color-primary: #152180;
  --color-secondary: #6c757d;
  --color-background: white;
  --color-text: #333;
  --color-gold: #D4A737;
}


#root {
  width: 100%;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Container */
main {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 800px;
  min-height: calc(100vh - 68px);
  background-color: white;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  border-radius: 0 0  20px 20px;
  margin: 0 10px 40px 10px; 
}

@media screen and (min-width: 800px) {
  main {
    padding: 40px;
  }
  
}
@media screen and (min-width: 600px) {
  main {
    padding: 0 20px;
    width: 100%;
  }
  
}

h1 {
  color: var(--color-primary);
  text-align: center;
  font-size: 5vw;
  font-family: 'Zapfino', cursive;
}

@media only screen and (min-width: 600px) {
  h1 {
    line-height: 2rem;
    font-size: 2rem;
    padding-top: 60px;
  }
}

h2 {
  color: var(--color-primary);
  text-align: center;
}


footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background-color: white;
  color: var(--color-text);
  width: 100vw;
}