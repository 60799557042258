.schedule {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-primary);
}

.schedule > * {
  width: 100%;
}

.schedule h3 {
  font-family: 'Zapfino', cursive;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .schedule {
    flex-direction: row;
  }
  .schedule > * {
    margin: 0 10px;
    width: 33%;
  }
  
}

.schema-item {
  display: flex;
  justify-content: "flex-start";
  margin: 10px 0;
  font-size: 14px;
}

.schema-item > * {
  margin-right: 10px;
}

.schema-item > img {
  width: 20px;
  height: 20px;
}
.schema-time {
  color: var(--color-gold);
}