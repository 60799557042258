
/* Main */
.mainPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainPage img {
  width: 80%;
  margin: 0 0 30px 0;
  border: 5px solid white;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
}

.mainPage div {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 30px;
}

@media screen and (min-width: 800px) {
  .mainPage {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  .mainPage div {
    margin: 30px;
  }
}
@media screen and (min-width: 600px) {
  .mainPage img {
    max-width: 400px;
    margin: 30px;
  }
  
}