header {
  position: sticky;
  display: flex;
  top: 0;
  left: 0;
  color: var(--color-text);
  background-color: white;
  z-index: 2;
  padding: 10px 20px; /* Add padding for better appearance */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}
.mobile {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 800px;
}
.hamburger {
  align-self: flex-end;
}
.App-header {
  width: calc(100% - 40px); 
}
header h1 {
  flex-grow: 1;
  display: inline-block;
  font-size: 1rem;
  padding-left: 60px;
  padding-top: 10px;
  margin: 0;
}
.openMenu {
  display: flex;
  position: absolute;
  top: 67px;
  left: 0;
  background-color: white;
  width: 100%;
}

.openMenu ul {
  list-style-type: none;
  width: 100%;
  padding: 20px;
  margin: 0;
}
.openMenu ul li {
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid var(--color-secondary);
  width: 100%;
  
}
.openMenu ul li .active {
  font-weight: bold;
}
.openMenu ul li a {
  text-decoration: none;
  color: var(--color-text);
}
.openMenu ul li:hover {
  font-weight: bold;
}
@media screen and (min-width: 600px) {
  header {
    display: none;
  }
  
}

.desktop {
  display: none;
}
@media screen and (min-width: 600px) {
  .desktop {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
  }
  .mobile {
    display: none;
  }
}

.desktop nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  text-transform: uppercase;
  position: relative;
  padding: 20px 0;
}
.desktop nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height of the line */
  background: linear-gradient(to right, transparent, #ccc, transparent); 
}
.desktop nav::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the height of the line */
  background: linear-gradient(to right, transparent, #ccc, transparent); 
}

.desktop nav a {
  text-decoration: none;
  color: var(--color-text); 
}
.desktop nav a:hover {
  font-weight: bold;
}
.desktop nav a.active {
  font-weight: bold;
}